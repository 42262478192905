import { useContext, useEffect } from 'react';
import Head from 'next/head';
import Image from 'next/legacy/image';
import styled from '@emotion/styled';
import { Flex, Button } from '@chakra-ui/react';
import { AuthContext } from 'contexts/AuthContext';
import { useRouter } from 'next/router';

const StyledBackground = styled.div<{ background: number }>(
  {
    position: 'absolute',
    minHeight: '100vh',
    width: '100%',
    opacity: '0.2',
    zIndex: '0',
    transition: 'all 2s ease-in',
  },
  props => ({
    '@media (min-width: 320px)': {
      background: `url(/images/background${props.background}-sm.jpg) no-repeat center center scroll`,
      backgroundSize: 'cover',
      overflow: 'hidden',
    },
    '@media (min-width: 768px)': {
      background: `url(/images/background${props.background}-lg.jpg) no-repeat center center fixed`,
      backgroundSize: 'cover',
      overflow: 'hidden',
    },
    '@media (min-width: 1280px)': {
      background: `url(/images/background${props.background}-xl.jpg) no-repeat center center fixed`,
      backgroundSize: 'cover',
      overflow: 'hidden',
    },
  })
);

const StyledOverlay = styled.div`
  position: absolute;
  background-image: linear-gradient(
    to top,
    #24dbdd,
    #2ee1d5,
    #41e6cc,
    #56ebc1,
    #6cefb5
  );
  width: 100%;
  min-height: 100vh;
  opacity: 0.7;
  z-index: -1;
  transition: background-image 2s ease-in;
`;

interface IHome {
  background: number;
  authorizedUsers: string[];
}

export default function Home({ background, authorizedUsers = [] }: IHome) {
  const { user, signInWithGoogle } = useContext(AuthContext);
  const router = useRouter();

  useEffect(() => {
    if (user) {
      router.push(
        authorizedUsers.includes(user.email) ? '/fortinis' : '/compras/nova'
      );
    }
  }, [user, router, authorizedUsers]);

  return user ? null : (
    <Flex flexDirection="column" height="100%" justifyContent="stretch">
      <StyledOverlay />
      <StyledBackground background={background} />
      <Head>
        <title>Fortinis - Entrar</title>
        <meta
          name="description"
          content="Gestão de Colaboradores e Voluntários - Fortini Investimento Social"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Flex
        as="main"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="calc(100% - 63px)"
      >
        <Button
          colorScheme="whiteAlpha"
          fontWeight="normal"
          color="gray.700"
          onClick={signInWithGoogle}
          leftIcon={
            <Image
              src="/images/google-icon.svg"
              alt="Google logo"
              width={18}
              height={18}
            />
          }
        >
          Entrar com o Google
        </Button>
      </Flex>
      <Flex
        as="footer"
        justifyContent="center"
        position="fixed"
        bottom="0"
        width="100%"
        pb={2}
      >
        <Image
          src="/images/fortini-white-logo.png"
          alt="Fortini Investimento Social"
          title="Fortini Investimento Social"
          width={183}
          height={55}
        />
      </Flex>
    </Flex>
  );
}

export async function getStaticProps() {
  return {
    props: {
      background: Math.floor(Math.random() * (8 - 0) + 1),
      authorizedUsers: [
        'maiara@fortini.org.br',
        'ystael@fortini.org.br',
        'bruno@fortini.org.br',
        'izabela.soares@fortini.org.br',
      ],
    },
  };
}
